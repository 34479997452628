import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/HelloWorld'
import services from '../components/v-services'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/services/:id',
    name: 'services',
    component: services,
    props: true
  },
  {
    path: '/resheniya',
    name: 'Resheniya',
    component: () => import(/* webpackChunkName: "about" */ '../components/Resheniya')
  },
  {
    path: '/wins',
    name: 'wins',
    component: () => import(/* webpackChunkName: "about" */ '../components/Wins')
  },
  {
    path: '/free',
    name: 'free',
    component: () => import(/* webpackChunkName: "about" */ '../components/Free')
  },
  {
    path: '/chance',
    name: 'chance',
    component: () => import(/* webpackChunkName: "about" */ '../components/chance')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
