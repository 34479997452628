<template>
  <div class="footer" style="padding-bottom: 90px">
    <div class="container">
      <div class="left-container">
        <div>
          <a href="https://reiting-uristov.ru">Автоюрист - лидер на проекте<br>РЕЙТИНГ ЮРИСТОВ<br>
            reiting-uristov.ru</a>
          <p>Работаем под контролем Министерства Юстиции РФ</p>
          <p>
            <a target="_blank" style="display: flex; align-items: center" href="https://pristav.mfucentre.ru/minust.pdf">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.875 18H21.375C20.7536 18 20.25 18.5036 20.25 19.125V28.125C20.25 28.7464 20.7536 29.25 21.375 29.25H25.875C27.1176 29.25 28.125 28.2426 28.125 27V20.25C28.125 19.0074 27.1176 18 25.875 18ZM25.875 27H22.5V20.25H25.875V27Z" fill="#FFED00"/>
                <path d="M36 20.25V18H30.375C29.7536 18 29.25 18.5036 29.25 19.125V29.25H31.5V24.75H36V22.5H31.5V20.25H36Z" fill="#FFED00"/>
                <path d="M2.25 32.6248V3.37477C2.25 2.75342 2.75365 2.24977 3.375 2.24977H20.25V6.74977C20.25 7.99241 21.2574 8.99977 22.5 8.99977H27V12.3748H29.25V7.87477C29.2518 7.57573 29.1343 7.2883 28.9237 7.07602L22.1737 0.326024C21.9615 0.115438 21.674 -0.00191369 21.375 -0.00022619H3.375C1.51102 -0.00022619 0 1.51086 0 3.37477V32.6248C0 34.4888 1.51102 35.9998 3.375 35.9998H11.25V33.7498H3.375C2.75372 33.7498 2.25 33.2461 2.25 32.6248Z" fill="#FFED00"/>
                <path d="M16.875 18H12.375C11.7536 18 11.25 18.5036 11.25 19.125V29.25H13.5V25.875H16.875C18.1176 25.875 19.125 24.8676 19.125 23.625V20.25C19.125 19.0074 18.1176 18 16.875 18ZM16.875 23.625H13.5V20.25H16.875V23.625Z" fill="#FFED00"/>
              </svg>
              Посмотрите наши учредительные документы
            </a>
          </p>
        </div>
        <div>
          <p style="margin: 0">Бесплатная федеральная линия</p>
          <a style="font-weight: 800;font-size: 24px;line-height: 33px;margin: 15px 0;display: block;" title="Вызов" href="tel:+78002013236">8-800-201-32-36</a>
          <p>
            <a href="https://rutube.ru/channel/1138528/">
              <img src="../../assets/footer/icon-1.png" alt="">Подпишитесь на видеоканал
            </a>
          </p>
          <p style="margin: 5px 0">
            <a href="https://vk.com/club7375007">
              <img src="../../assets/footer/icon.png" alt="">Сотни положительных отзывов
            </a>
          </p>
          <p>
            <a target="_blank" href="/policy.pdf">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M18 2H12V9L9.5 7.5L7 9V2H6A2 2 0 0 0 4 4V20A2 2 0 0 0 6 22H18A2 2 0 0 0 20 20V4A2 2 0 0 0 18 2M14 12A2 2 0 1 1 12 14A2 2 0 0 1 14 12M18 20H10V19C10 17.67 12.67 17 14 17S18 17.67 18 19Z" />
              </svg>
              Пользовательское соглашение
            </a>
          </p>
        </div>
      </div>
      <div class="right-container">
        <p style="margin-bottom: 24px">Онлайн-сервисы по возврату прав</p>
        <p>Бесплатный вебинар по возврату прав</p>
        <a href="https://xn----7sbbi4acmpdfiyoke2q.xn--p1ai/besplatno-video">Запишитесь прямо сейчас
          <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z" fill="#FFED00"/>
          </svg>
        </a>
        <p>Решения по такому же делу о лишении прав как Ваше</p>
        <a href="https://mypobeda.avtourist.info/">Получите бесплатно
          <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z" fill="#FFED00"/>
          </svg>
        </a>
        <p>Готовые документы по возврату прав за 299 руб</p>
        <a href="https://avtourist.net/">
          Получить документы
          <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z" fill="#FFED00"/>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="scss">
  .container {
    padding: 0 50px;
    @media (max-width: 1200px) {
      padding:25px
    }
    @media (max-width: 768px) {
      padding:15px
    }
    @media (max-width: 420px) {
      padding:5px
    }
  }
  .footer {
    margin-top: 40px;
    padding: 40px 0px;
    min-height: 50px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FFFFFF;
    color: #ffffff;
    .container {
      display: flex;
    }
    .left-container {
      display: flex;
      align-items: flex-start;
      position: relative;
      width: 50%;
      @media (max-width: 1000px) {
        flex-wrap: wrap;
      }
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background: rgba(255,255,255,.4);
        right: 0;
      }
      div {
        width: 270px;
        max-width: 270px;
      }
      a {
        color: #ffffff;
      }
      svg {
        margin-right: 10px;
      }
      p, a {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        img {
          margin-right: 10px;
        }
      }
    }
    .right-container {
      margin-left: 50px;
      width: 50%;
      a {
        margin-bottom: 24px;
        color: #FFFFFF;
        text-decoration-line: underline;
        opacity: 0.6;
        display: block;
      }
      p {
        margin: 0;
      }
    }
  }
</style>
